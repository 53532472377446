import {
  all,
  apply,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { getStamps } from './actions';
import getStampService from '../../services/stamp';

export function* handleGetStamps() {
  try {
    const stampService = getStampService();

    yield put(getStamps.request());

    const res = yield apply(stampService, stampService.get);

    yield put(getStamps.success(res));
  } catch (err) {
    yield put(getStamps.failure(err));
  } finally {
    yield put(getStamps.fulfill());
  }
}

export default function* watch() {
  yield all([
    takeLatest(getStamps.TRIGGER, handleGetStamps),
  ]);
}
