import React from 'react';
import { Table } from 'reactstrap';
import { PropTypesStamps } from '../../__props__/stamps';

const StampsTable = ({ stamps }) => (
  <Table>
    <thead>
      <tr>
        <th>Issuer</th>
        <th>Recipient</th>
        <th>Recipient type</th>
        <th>Start date</th>
        <th>End date</th>
        <th>Time</th>
        <th>Skills</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      {stamps.map((stamp) => {
        const recipient = stamp.recipient
          ? stamp.recipient.emailAddress || stamp.recipient.phoneNumber
          : null;
        const issuer = stamp.createdBy && stamp.createdBy.emailAddress;
        const hours = Math.floor(stamp.minutes / 60);
        let minutes = stamp.minutes % 60;

        if (minutes < 10) {
          minutes = `0${minutes}`;
        }

        return (
          <tr key={stamp.id}>
            <td>{issuer}</td>
            <td>{recipient}</td>
            <td>{stamp.recipientType}</td>
            <td>{stamp.start}</td>
            <td>{stamp.end}</td>
            <td>{hours}:{minutes}</td>
            <td>{(stamp.skills || []).join()}</td>
            <td>{stamp.status}</td>
          </tr>
        );
      })}
    </tbody>
  </Table>
);

StampsTable.propTypes = {
  stamps: PropTypesStamps,
};

StampsTable.defaultProps = {
  stamps: [],
};

export default StampsTable;
