import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';
import Route from '../../components/route';
import DashboardPage from '../dashboard';
import { init } from '../../store/app/actions';

// Main single page app entry point and router. Relies upon the "loaded" state
// from the redux-async-initial-state middleware to determine whether or not to
// render the router. This is necessary to handle cases where the initial load
// is on a protected route. In such cases we need to asynchronously load the
// session state from Cognito and would redirect to the login page before that
// request completed.
const App = ({
  authenticated,
  loaded,
  onMount,
  groups,
  busy,
}) => {
  useEffect(() => {
    onMount();
  }, [loaded]);

  return (
    <Router>
      <React.Fragment>
        <Helmet title="Tendo Hub" />
        {busy && <div className="busy" />}
        <Container fluid>
          {loaded && (
            <React.Fragment>
              <Route
                exact
                path="/"
                component={DashboardPage}
                authenticated={authenticated}
                groups={groups}
                requiresAuth
              />
            </React.Fragment>
          )}
        </Container>
      </React.Fragment>
    </Router>
  );
};

App.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  groups: PropTypes.arrayOf(PropTypes.string),
  loaded: PropTypes.bool.isRequired,
  onMount: PropTypes.func.isRequired,
  busy: PropTypes.bool.isRequired,
};

App.defaultProps = {
  groups: [],
};

function mapStateToProps({
  asyncInitialState,
  busy,
  login: {
    authenticated,
    groups,
  },
}) {
  return {
    loaded: asyncInitialState.loaded,
    authenticated,
    groups,
    busy,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onMount: () => dispatch(init()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
