import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Amplify from 'aws-amplify';
import App from './containers/app';
import store from './store';
import Config from './config';

import './styles/index.scss';

Amplify.configure({
  Auth: {
    region: Config.Aws.REGION,
    userPoolId: Config.Aws.Cognito.USER_POOL_ID,
    identityPoolId: Config.Aws.Cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: Config.Aws.Cognito.USER_POOL_CLIENT_ID,
    cookieStorage: {
      domain: '.tendo.id',
      secure: true,
    },
  },
  API: {
    endpoints: [
      {
        name: 'tendo',
        endpoint: Config.Aws.APIGateway.URL,
        region: Config.Aws.REGION,
      },
    ],
  },
});

// Render the client-side React application.
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
