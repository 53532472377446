import React from 'react';
import { Table } from 'reactstrap';
import { PropTypesUsers } from '../../__props__/users';

function getAttributeValue(user, attrName) {
  if (user && Array.isArray(user.Attributes)) {
    const attr = user.Attributes.find(a => a.Name === attrName);

    if (attr) {
      return attr.Value;
    }
  }

  return null;
}

const UsersTable = ({ users }) => (
  <Table>
    <thead>
      <tr>
        <th>Email</th>
        <th>Name</th>
        <th>Status</th>
        <th>Type</th>
        <th>Stamps issued</th>
        <th>Stamps verified</th>
        <th>Last verification</th>
      </tr>
    </thead>
    <tbody>
      {users.map((user) => {
        const verifiedStamps = user.stamps.filter(stamp => stamp.status === 'verified');
        let lastVerification = 'N/A';

        if (verifiedStamps.length) {
          lastVerification = verifiedStamps.reduce((prev, stamp) => {
            if (new Date(stamp.end) > new Date(prev)) {
              return stamp.end;
            }

            return prev;
          }, verifiedStamps[0].end);
        }

        // Default everyone to being an employee
        user.type = 'Employee';

        // If they have a value set for this attribute they belong to an organisation or are the
        // organisation, if we check the manager flag we'll determine which.
        if (getAttributeValue(user, 'custom:business')) {
          user.type = 'Organisation';
        }

        if (getAttributeValue(user, 'custom:manager')) {
          user.type = 'Manager';
        }

        return (
          <tr key={user.Username}>
            <td>{getAttributeValue(user, 'email')}</td>
            <td>{getAttributeValue(user, 'name')}</td>
            <td>{user.UserStatus}</td>
            <td>{user.type}</td>
            <td>{user.stamps.length}</td>
            <td>{verifiedStamps.length}</td>
            <td>{lastVerification}</td>
          </tr>
        );
      })}
    </tbody>
  </Table>
);

UsersTable.propTypes = {
  users: PropTypesUsers,
};

UsersTable.defaultProps = {
  users: [],
};

export default UsersTable;
