import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Config from '../../config';

// Wrapper component for react-router routes to allow us to provide custom props
// to the actual route components via a render prop. Because this is the Tendo
// admin dashboard app, only users belonging to the Cognito "admins" group are
// treated as authenticated.
const CustomRoute = ({
  component: Component,
  requiresAuth,
  authenticated,
  groups,
  ...rest
}) => (
  /* eslint-disable react/prop-types */
  <Route
    render={(props) => {
      const route = <Component {...props} />;

      if (
        requiresAuth
        && (
          !authenticated
          || (
            authenticated
            && !groups.includes('admins')
          )
        )
      ) {
        window.location = Config.LOGIN_URL || 'https://app.tendo.id/login';

        // Useless render to prevent a React error throwing due to empty render
        // method.
        return <div />;
      }

      return route;
    }}
    {...rest}
  />
);

CustomRoute.propTypes = {
  component: PropTypes.func.isRequired,
  requiresAuth: PropTypes.bool,
  authenticated: PropTypes.bool,
  groups: PropTypes.arrayOf(PropTypes.string),
};

CustomRoute.defaultProps = {
  requiresAuth: false,
  authenticated: false,
  groups: [],
};

export default CustomRoute;
