import { getUsers } from './actions';

const initialState = {
  employees: [],
  employers: [],
  organisations: [],
  unconfirmed: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case getUsers.SUCCESS:
      return Object.assign({}, state, {
        employees: action.payload.employeeUsers,
        employers: action.payload.employerUsers,
        organisations: action.payload.organisationUsers,
        unconfirmed: action.payload.unconfirmedUsers,
      });
    case getUsers.FAILURE:
      return state;
    default:
      return state;
  }
};
