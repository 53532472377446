export const initialState = {
  authenticated: false,
  groups: null,
  logout: {},
};

// Empty reducer to set up the relevant properties on the state tree. This is
// necessary to allow the app to operate with the authentication state from the
// main Tendo app.
export default (state = initialState) => state;
