import BaseService from '../base';

class UserService extends BaseService {
  async get() {
    let users = [];

    users = await super.get('/admin/users');

    return {
      success: true,
      ...users,
    };
  }
}

let instance;

export default (...args) => {
  if (!instance) {
    instance = new UserService(...args);
  }

  return instance;
};
