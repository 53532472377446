import { getUsers } from '../users/actions';
import { getStamps } from '../stamps/actions';

export default (state = false, action) => {
  switch (action.type) {
    case getUsers.REQUEST:
    case getStamps.REQUEST:
      return true;
    case getUsers.FULFILL:
    case getStamps.FULFILL:
      return false;
    default:
      return state;
  }
};
