import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { PropTypesUsers } from '../../__props__/users';
import { PropTypesStamps } from '../../__props__/stamps';
import { getUsers } from '../../store/users/actions';
import { getStamps } from '../../store/stamps/actions';
import UsersTable from '../../components/users-table';
import StampsTable from '../../components/stamps-table';

const Dashboard = ({
  users,
  stamps,
  getUsers, // eslint-disable-line no-shadow
  getStamps, // eslint-disable-line no-shadow
  totalTime,
  employeeCount,
  employerCount,
  organisationCount,
}) => {
  useEffect(() => {
    getUsers();
    getStamps();
  }, []);

  const hours = Math.floor(totalTime / 60);
  let minutes = totalTime % 60;

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  return (
    <div className="page-dashboard">
      <Row>
        <Col>
          <h2>Registered employees</h2>
          {employeeCount}
        </Col>
        <Col>
          <h2>Registered managers</h2>
          {employerCount}
        </Col>
        <Col>
          <h2>Registered organisations</h2>
          {organisationCount}
        </Col>
        <Col>
          <h2>Total hours logged</h2>
          {hours}:{minutes}
        </Col>
      </Row>
      <Row>
        <Col className="dashboard-users">
          <h2>Registered users</h2>
          <UsersTable users={users} />
        </Col>
        <Col className="dashboard-stamps">
          <h2>Stamps</h2>
          <StampsTable stamps={stamps} />
        </Col>
      </Row>
    </div>
  );
};

Dashboard.propTypes = {
  getUsers: PropTypes.func.isRequired,
  getStamps: PropTypes.func.isRequired,
  users: PropTypesUsers,
  stamps: PropTypesStamps,
  totalTime: PropTypes.number.isRequired,
  employeeCount: PropTypes.number.isRequired,
  employerCount: PropTypes.number.isRequired,
  organisationCount: PropTypes.number.isRequired,
};

Dashboard.defaultProps = {
  users: [],
  stamps: [],
};

function mapStateToProps({
  users: {
    employees,
    employers,
    unconfirmed,
    organisations,
  },
  stamps: {
    stamps,
  },
}) {
  const users = employees
    .concat(employers)
    .concat(organisations)
    .concat(unconfirmed)
    .sort((a, b) => {
      const dateA = new Date(a.UserCreateDate);
      const dateB = new Date(b.UserCreateDate);

      return dateB - dateA;
    });

  const totalTime = stamps.reduce((total, stamp) => total + +stamp.minutes, 0);
  const stampsByEmployee = stamps.reduce((obj, stamp) => {
    if (stamp.recipientType === 'employer' && stamp.recipient) {
      if (stamp.createdBy) {
        obj[stamp.createdBy.id] = obj[stamp.createdBy.id] || [];
        obj[stamp.createdBy.id].push(stamp);
      }
    }

    return obj;
  }, {});

  users.forEach((user) => {
    const userId = user.Attributes.find(attr => attr.Name === 'custom:userId') || {};

    user.stamps = stampsByEmployee[userId.Value] || [];
  });

  return {
    employeeCount: employees.length,
    employerCount: employers.length,
    organisationCount: organisations.length,
    totalTime,
    users,
    stamps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUsers: () => dispatch(getUsers()),
    getStamps: () => dispatch(getStamps()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
