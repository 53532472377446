import {
  all,
  apply,
  put,
  takeLatest,
} from 'redux-saga/effects';
import getUserService from '../../services/user';
import { getUsers } from './actions';

export function* handleGetUsers() {
  try {
    const stampService = getUserService();

    yield put(getUsers.request());

    const res = yield apply(stampService, stampService.get);

    yield put(getUsers.success(res));
  } catch (err) {
    yield put(getUsers.failure(err));
  } finally {
    yield put(getUsers.fulfill());
  }
}

export default function* watch() {
  yield all([
    takeLatest(getUsers.TRIGGER, handleGetUsers),
  ]);
}
