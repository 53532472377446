import BaseService from '../base';

class StampService extends BaseService {
  async get() {
    let stamps = [];

    stamps = await super.get('/admin/stamps');

    return {
      success: true,
      ...stamps,
    };
  }
}

let instance;

export default (...args) => {
  if (!instance) {
    instance = new StampService(...args);
  }

  return instance;
};
