import PropTypes from 'prop-types';

// The user objects are expected to follow the format returned by AWS Cognito.
export const PropTypesUser = PropTypes.shape({
  Attributes: PropTypes.arrayOf(PropTypes.shape({
    Name: PropTypes.string,
    Value: PropTypes.any,
  })),
  Enabled: PropTypes.bool,
  UserCreateDate: PropTypes.string,
  UserLastModifiedDate: PropTypes.string,
  UserStatus: PropTypes.string,
  Username: PropTypes.string,
});

export const PropTypesUsers = PropTypes.arrayOf(PropTypesUser);
